import Banner from '../../assets/Banner.png'
import Copy from '../../assets/content_copy.png'
import Address from '../../assets/address.png'
import LineBreak1 from '../../assets/Horizontal_Lines/Line_Break_01.png'
import Video from '../../assets/CZfinalAnimation.mp4';
import './Page1.css';
import { useEffect, useRef } from 'react';

const Page1 = () => {

    const videoRef = useRef(null);

    useEffect(() => {
        const videoElement = videoRef.current;

        if (videoElement) {
            videoElement.play().catch(error => {
            });

            const handleVideoEnd = () => {
                videoElement.currentTime = 0;
                videoElement.play().catch(error => {
                });
            };

            videoElement.addEventListener('ended', handleVideoEnd);

            return () => {
                videoElement.removeEventListener('ended', handleVideoEnd);
            };
        }
    }, []);

    return (
        <div className='page1'>
            {/* <img src={Banner} alt="Banner" className="banner" /> */}
            <video ref={videoRef} className='sample-video' Autoplay muted>
                <source src='https://czrun-cdn.s3.ap-southeast-1.amazonaws.com/Gameplay_czrunz.mp4' type="video/mp4" />
            </video>
            <div className='page1-content'>
                <div className='title'>CZ RETURNS. THIS IS IT!</div>
                <div className='ticker'>TICKER: $CZRUNZ</div>
                <div className='ca-content'>
                    <img className='ca-address' src={Address} alt="Address" />
                    <div className='ca-data-container'>
                        <div className='ca-data'>C/A:</div>
                        <div className='ca-data'>0x26aa9b3d8a49a2ed849ac66ea9aa37ee36bc6b24 </div>
                    </div>
                    <div className='copy-icon' onClick={() => navigator.clipboard.writeText('0x26aa9b3d8a49a2ed849ac66ea9aa37ee36bc6b24')}><img src={Copy} alt="Copy" /></div>
                </div>
            </div>
            <img src={LineBreak1} alt="Line Break 1" className="line-break-1" />
        </div>
    )
}

export default Page1;
