import { useEffect, useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import './Page4.css';
import Rect35 from '../../assets/Rectangle_35.png'
import Line5 from '../../assets/Horizontal_Lines/Line_Break_05.png';
import Hor1 from '../../assets/Horizontal_carousel/Group 25.png';
import Hor2 from '../../assets/Horizontal_carousel/Horizontal_Line.png';
import Hor3 from '../../assets/Horizontal_carousel/Trump_Vs_Biden.png';
import SliderImage1 from '../../assets/Horizontal_carousel/Fake_news_prison_meet_up.jpg';
import SliderImage2 from '../../assets/Horizontal_carousel/Fake_news_Trump.jpg';
import SliderImage3 from '../../assets/Horizontal_carousel/Cz_white_house.jpg';
import SliderImage4 from '../../assets/Horizontal_carousel/IMG_2510.jpg';
import SliderImage5 from '../../assets/Horizontal_carousel/IMG_2509.jpg';
import SliderImage6 from '../../assets/Horizontal_carousel/IMG_2508.jpg';
import SliderImage7 from '../../assets/Horizontal_carousel/IMG_2507.jpg';
import Line6 from '../../assets/Horizontal_Lines/Line_Break_06.png';
import Line7 from '../../assets/Horizontal_Lines/Line_Break_08.png';
import Video from '../../assets/Gameplay.mp4';
import VerticalScroll from '../../assets/VerticalScroll.png';


const Page4 = () => {
    const settings = {
        // dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1500,
    };

    const videoRef = useRef(null);

    useEffect(() => {
        const videoElement = videoRef.current;

        if (videoElement) {
            videoElement.play().catch(error => {
            });

            const handleVideoEnd = () => {
                videoElement.currentTime = 0;
                videoElement.play().catch(error => {
                });
            };

            videoElement.addEventListener('ended', handleVideoEnd);

            return () => {
                videoElement.removeEventListener('ended', handleVideoEnd);
            };
        }
    }, []);

    return (
        <div className='page4'>
            <div className='page-title'>VAO GOVERNANCE BY THE PEOPLE, FOR THE PEOPLE! </div>
            <div className='page-content'>$CZRUNZ is a community-driven token built on Base, with a mission to celebrate Changpeng Zhao and his contributions to the cryptocurrency world. The project is governed by a (VAO) of Vendetta Anon Oracles which controls a significant portion of the token supply to foster the community collaborative meme movement #Chainge4all!</div>
            <img src={Rect35} alt='rect35' className='img' style={{ backgroundColor: "#FF6B00" }} />
            <img src={Line5} alt='line5' className='img line-5' />

            <div className="hor-carousel" style={{}}>
                <video ref={videoRef} className='sample-video' Autoplay muted>
                    <source src='https://czrun-cdn.s3.ap-southeast-1.amazonaws.com/CZ_Runz.mp4' type="video/mp4" />
                </video>
                {/* <img src={VerticalScroll} alt='line5' className='' style={{ width: "100%" }} /> */}
            </div>
            <img src={Line6} alt='line6' className='img line-6' />
            <div className='' style={{ backgroundColor: "black", position: "relative", top: "-4px" }}>
                <Slider {...settings}>
                    <div>
                        <img src={SliderImage1} alt='hor1' className='img hor-img' />
                    </div>
                    <div>
                        <img src={SliderImage2} alt='hor2' className='img hor-img ' />
                    </div>
                    <div>
                        <img src={SliderImage3} alt='hor3' className='img hor-img' />
                    </div>
                    <div>
                        <img src={SliderImage4} alt='hor3' className='img hor-img' />
                    </div>
                    <div>
                        <img src={SliderImage5} alt='hor3' className='img hor-img' />
                    </div>
                    <div>
                        <img src={SliderImage6} alt='hor3' className='img hor-img' />
                    </div>
                    <div>
                        <img src={SliderImage7} alt='hor3' className='img hor-img' />
                    </div>
                </Slider>
            </div>
            <img src={Line7} alt='line7' className='img line-7' style={{ top: "-5px", position: "relative" }} />
        </div>
    )
}

export default Page4;
