import React from 'react';
import RightImage from '../../assets/IMG.png';
import Watch1 from '../../assets/Watch_This_Section/Group 4.png';
import Watch2 from '../../assets/Watch_This_Section/Group 5.png';
import Watch3 from '../../assets/Watch_This_Section/Group 6.png';
import Watch4 from '../../assets/Watch_This_Section/Group 7.png';
import Watch5 from '../../assets/Watch_This_Section/Group 8.png';
import LineBreak4 from '../../assets/Horizontal_Lines/Line_Break_04.png';
import LineBreak3 from '../../assets/Horizontal_Lines/Line_Break_03.png';
import JOINTHEUPRISING from '../../assets/JOIN_THE_UPRISING .png'
import You_Tube from '../../assets/You_Tube.png'
import Twitter from '../../assets/Twitter.png'
import Message from '../../assets/Message.png'
import tictok from '../../assets/tictok.png'
import './Page3.css';

const Page3 = () => {
    return (
        <div className="page3">
            <div className='top-content'>
                <div className='left'>
                    <div className='how-to-buy'>HOW TO BUY</div>
                    <div className='step-title'>STEP 1</div>
                    <div className='step-text'>
                        Have or create a metamask,
                        phantom or your preferred wallet.
                    </div>
                    <div className='step-title'>STEP 2</div>
                    <div className='step-text'>
                        Buy Etherum and have it in your wallet.
                    </div>
                    <div className='step-title'>STEP 3</div>
                    <div className='step-text'>
                        Connect it to Camelot.
                    </div>
                    <div className='step-title'>STEP 4</div>
                    <div className='step-text'>
                        Swap XAI or ETH for $CZRUNZ
                    </div>
                    <div className='watch-this'>OUR PARTNERS</div>
                </div>
                <div className='right'>
                    <img src={RightImage} alt='right' className='right-image' />
                </div>
            </div>
            <div className='bottom-content'>
                <div className='image-box'>
                    <img src={Watch1} alt='watch1' className='watch-image' />
                </div>
                <div className='image-box'>
                    <img src={Watch2} alt='watch2' className='watch-image' />
                </div>
                <div className='image-box'>
                    <img src={Watch3} alt='watch3' className='watch-image' />
                </div>
                <div className='image-box'>
                    <img src={Watch4} alt='watch4' className='watch-image' />
                </div>
                <div className='image-box'>
                    <img src={Watch5} alt='watch5' className='watch-image' />
                </div>
            </div>
            <div style={{ position: "absolute", width: "100%" }}>
                <img src={LineBreak3} alt='linebreak4' className='linebreak4' style={{ width: "100%" }} />
            </div>
            <div style={{ position: "relative", display: "flex" }}>
                <div style={{ marginLeft: "60px", display: "flex" }} className='Icons'>
                    <span>
                        <a href="https://www.youtube.com/@CZrunz" target="_blank" className='social-icon'>
                            <img className='Social_Icon' src={You_Tube} alt='linebreak4' />
                        </a>
                    </span>
                    <span>
                        <a href="https://t.me/CZRUNZ" target="_blank" className='social-icon'>
                            <img className='Social_Icon' src={Message} alt='linebreak4' />
                        </a>
                    </span>
                    <span>
                        <a href="https://x.com/CzRunz" target="_blank" className='social-icon'>
                            <img className='Social_Icon' src={Twitter} alt='linebreak4' />
                        </a>
                    </span>
                    <span>
                        <a href="https://www.tiktok.com/@czrunz" target="_blank" className='social-icon'>
                            <img className='Social_Icon' src={tictok} alt='linebreak4' style={{ height: "76px", width: "76px" }} />
                        </a>
                    </span>
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                    <span className='uprising-text'> <img src={JOINTHEUPRISING} alt='linebreak4' className='uprisingText' /> </span>
                </div>

            </div>
        </div>
    );
}

export default Page3;
